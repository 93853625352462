import React from 'react';
import { Link } from "react-router-dom";

const Nav = ({data}) => {

  const scrollIntoNavbarView = () => {
    const content = document.getElementById('content');
    content?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    const navbarHamburger = document.getElementsByClassName('navbar-toggler')[0];
    navbarHamburger?.click();
  }

  return (
    <div className="sticky">
     <nav className="navbar navbar-expand-lg navbar-light bg-light" id="navbar">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div>
              <ul className="navbar-nav mr-auto" >
               <li className="">
                  <h6 style={{cursor: 'pointer'}} className="nav-link">
                    <Link to="/about" onClick={scrollIntoNavbarView} className="nav-link text-black">{data.tab0.name}</Link>
                  </h6>
                </li>
                <li className=" dropdown">
                  <h6 className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <a className="nav-link text-dark" href="#">{data.tab1.name}</a>
                  </h6>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link className="dropdown-item" to="/osb" onClick={scrollIntoNavbarView}>{data.tab1.subTab1}</Link>
                      <Link className="dropdown-item" to="/svet-koroba" onClick={scrollIntoNavbarView}>{data.tab1.subTab2}</Link>
                      <Link className="dropdown-item" to="/torc-koroba" onClick={scrollIntoNavbarView}>{data.tab1.subTab3}</Link>
                      <Link className="dropdown-item" to="/viveski-kontrajur" onClick={scrollIntoNavbarView}>{data.tab1.subTab4}</Link>
                      <Link className="dropdown-item" to="/viveski-bez-podsvetki" onClick={scrollIntoNavbarView}>{data.tab1.subTab5}</Link>
                      <Link className="dropdown-item" to="/piloni" onClick={scrollIntoNavbarView}>{data.tab1.subTab6}</Link>
                      <Link className="dropdown-item" to="/brend-avto" onClick={scrollIntoNavbarView}>{data.tab1.subTab7}</Link>
                      <Link className="dropdown-item" to="/neon-viveski" onClick={scrollIntoNavbarView}>{data.tab1.subTab8}</Link>
                      <Link className="dropdown-item" to="/bannery" onClick={scrollIntoNavbarView}>{data.tab1.subTab9}</Link>
                  </div>
                </li>
                <li className="">
                <h6 className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <a className="nav-link text-dark" href="#">{data.tab2.name}</a>
                </h6>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/vizitki" onClick={scrollIntoNavbarView}>{data.tab2.subTab1}</Link>
                  <Link className="dropdown-item" to="/listovie-byklety" onClick={scrollIntoNavbarView}>{data.tab2.subTab2}</Link>
                  <Link className="dropdown-item" to="/calendars" onClick={scrollIntoNavbarView}>{data.tab2.subTab3}</Link>
                  <Link className="dropdown-item" to="/bloknoty" onClick={scrollIntoNavbarView}>{data.tab2.subTab4}</Link>
                </div>
                </li>
                <li className="">
                <h6 className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <a className="nav-link text-dark" href="#">{data.tab3.name}</a>
                </h6>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/pechat-na-tkani" onClick={scrollIntoNavbarView}>{data.tab3.subTab1}</Link>
                  <Link className="dropdown-item" to="/pechat-na-samokleike" onClick={scrollIntoNavbarView}>{data.tab3.subTab2}</Link>
                  <Link className="dropdown-item" to="/ploternaya-rezka" onClick={scrollIntoNavbarView}>{data.tab3.subTab3}</Link>
                </div>
                </li>
                <li className="">
                  <h6 style={{cursor: 'pointer'}} className="nav-link">
                  <Link to="/stands-equipment" onClick={scrollIntoNavbarView} className="nav-link text-dark">{data.tab4.name}</Link>
                  </h6>
                </li>
                <li className="">
                  <h6 style={{cursor: 'pointer'}} className="nav-link">
                    <Link to="/design-uslugi" onClick={scrollIntoNavbarView} className="nav-link text-black">{data.tab5.name}</Link>
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
