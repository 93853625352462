import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import AboutPageCarouselItem from "./AboutCarouselItem";

function AboutPageCarousel({currentCollection, setCurrentCollection, galleryName, setGalleryName, open, setOpen}) {
  const items = [
    {
      title: {
        ru: "Объемные световые буквы",
        en: 'Volumetric illuminated letters'
      },
      link: "/osb",
      image: "./media/NARYJKA/OSB/3.jpg",
      collection: "NARYJKA.OSB"
    },
    {
      title: {
        ru: "Световые короба",
        en: 'Light boxes'
      },
      link: "/svet-koroba",
      image: "./media/NARYJKA/SVET_KOROBA/5.jpeg",
      collection: "NARYJKA.SVET_KOROBA"
    },
    {
      title: {
        ru: "Торцевые короба",
        en: 'End boxes'
      },
      link: "/torc-koroba",
      image: "./media/NARYJKA/TORC_KOROBA/1.jpg",
      collection: "NARYJKA.TORC_KOROBA"
    },
    {
      title: {
        ru: "Вывески с контражуром",
        en: 'Signboards with backlight'
      },
      link: "/viveski-kontrajur",
      image: "./media/NARYJKA/VIVESKY_S_KONTRAJUROM/5.jpg",
      collection: "NARYJKA.VIVESKY_S_KONTRAJUROM"
    },
    {
      title: {
        ru: "Вывески без подсветки",
        en: 'Non-illuminated signage'
      },
      link: "/viveski-bez-podsvetki",
      image: "./media/NARYJKA/VIVESKI_BEZ_PODSVETKI/13.jpg",
      collection: "NARYJKA.VIVESKI_BEZ_PODSVETKI"
    },
    {
      title: {
        ru: "Пилоны",
        en: 'Pylons'
      },
      link: "/piloni",
      image: "./media/NARYJKA/PILONI/2.jpg",
      collection: "NARYJKA.PILONI"
    },
    {
      title: {
        ru: "Брендирование авто",
        en: 'Auto branding'
      },
      link: "/brend-avto",
      image: "./media/NARYJKA/BREND_AVTO/3.jpg",
      collection: "NARYJKA.BREND_AVTO"
    },
    {
      title: {
        ru: "Неоновые вывески",
        en: 'Neon signs'
      },
      link: "/neon-viveski",
      image: "./media/NARYJKA/NEON_VIVESKI/1.jpg",
      collection: "NARYJKA.NEON_VIVESKI"
    },
    {
      title: {
        ru: "Баннеры",
        en: 'Banners'
      },
      link: "/bannery",
      image: "./media/NARYJKA/BANNERY/3.jpg",
      collection: "NARYJKA.BANNERY"
    },
    {
      title: {
        ru: "Визитки",
        en: 'Business cards'
      },
      link: "/vizitki",
      image: "./media/POLYGRAPHY/VIZITKI/16.jpg",
      collection: "POLYGRAPHY.VIZITKI"
    },
    {
      title: {
        ru: "Листовки и буклеты",
        en: 'Flyers and booklets'
      },
      link: "/listovie-byklety",
      image: "./media/POLYGRAPHY/LISTOVIE_BYKLETY/14.jpg",
      collection: "POLYGRAPHY.LISTOVIE_BYKLETY"
    },
    {
      title: {
        ru: "Календари",
        en: 'Calendars'
      },
      link: "/calendars",
      image: "./media/POLYGRAPHY/CALENDARS/1.jpg",
      collection: "POLYGRAPHY.CALENDARS"
    },
    {
      title: {
        ru: "Блокноты",
        en: 'Notepads'
      },
      link: "/bloknoty",
      image: "./media/POLYGRAPHY/BLOKNOTY/1.jpg",
      collection: "POLYGRAPHY.BLOKNOTY"
    },
    {
      title: {
        ru: "Услуги Дизайна",
        en: 'Design Services'
      },
      link: "/design-uslugi",
      image: "./media/DESIGN_USLUGI/13.jpg",
      collection: "DESIGN_USLUGI"
    },
  ];

  return (
    <div className="carousel-container-custom">
      <Carousel navButtonsAlwaysVisible='true'>
        {items.map((item, i) => (
          <Item key={i} item={item} currentCollection={currentCollection} setCurrentCollection={setCurrentCollection} galleryName={galleryName} setGalleryName={setGalleryName} open={open} setOpen={setOpen}/>
        ))}
      </Carousel>
    </div>
  );
}

function Item({currentCollection, setCurrentCollection, galleryName, setGalleryName, open, setOpen, item}) {
  return (
    <Paper>
      <AboutPageCarouselItem item={item} currentCollection={currentCollection} setCurrentCollection={setCurrentCollection} galleryName={galleryName} setGalleryName={setGalleryName} open={open} setOpen={setOpen}/>
    </Paper>
  );
}

export default AboutPageCarousel;