import React from 'react';
import ImageGallery from 'react-image-gallery';
import {isMobile} from 'react-device-detect';

const ImageGal = ({currentCollection}) => {

  React.useEffect(() => {
    if(isMobile) {
      const fullScreenButton = document.getElementsByClassName('image-gallery-fullscreen-button')[0];
      // will click full screen button on mobile device for better UX
      fullScreenButton.click();
    }
  })

  return (
    <div>
      <ImageGallery items={currentCollection} showPlayButton={false}/>;
    </div>
  );
}

export default ImageGal;
