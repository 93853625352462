import React from 'react';
import { Routes, Route } from "react-router-dom";
import RouteInfo from "./RouteInfo";
import AboutPageCarouselItem from './AboutPageCarousel';
import AboutCompany from './AboutCompany';

const About = ({data}) => {

  React.useEffect(() => {
    const image = './media/bg1.jpeg';
    load(image).then(() => {
      const BGdiv = document.getElementById('intro');
      BGdiv.style.backgroundImage = `url(${image})`;
      BGdiv.style.animation = 'fade-in 1s';
    });
  });

  function load(src) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
    });
  }

  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  return (
    <div id="intro" className="bg-image">
      <div className="mask text-black">
        <div className="container d-flex align-items-center text-center h-100" style={{justifyContent: 'center'}}>
        <Routes>
          {/* home screen */}
          <Route path="/" element={
            <div>
              <AboutPageCarouselItem />
            </div>
          } />
          {/* home screen */}
            <Route path="/about" element={
              <div>
                <AboutCompany routeName={data.navbar.tab0.name} description={data.footer.shortBio}/>
              </div>
            } />
          {/* tab1 */}
          <Route path="osb" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab1} description={data.navbar.tab1.subTab1Tooltip} gallery={'NARYJKA.OSB'}/>} />
          <Route path="svet-koroba" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab2} description={data.navbar.tab1.subTab2Tooltip} gallery={'NARYJKA.SVET_KOROBA'}/>} />
          <Route path="torc-koroba" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab3} description={data.navbar.tab1.subTab3Tooltip} gallery={'NARYJKA.TORC_KOROBA'}/>} />
          <Route path="viveski-kontrajur" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab4} description={data.navbar.tab1.subTab4Tooltip} gallery={'NARYJKA.VIVESKY_S_KONTRAJUROM'}/>} />
          <Route path="viveski-bez-podsvetki" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab5} description={data.navbar.tab1.subTab5Tooltip} gallery={'NARYJKA.VIVESKI_BEZ_PODSVETKI'}/>} />
          <Route path="piloni" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab6} description={data.navbar.tab1.subTab6Tooltip} gallery={'NARYJKA.PILONI'}/>} />
          <Route path="brend-avto" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab7} description={data.navbar.tab1.subTab7Tooltip} gallery={'NARYJKA.BREND_AVTO'}/>} />
          <Route path="neon-viveski" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab8} description={data.navbar.tab1.subTab8Tooltip} gallery={'NARYJKA.NEON_VIVESKI'}/>} />
          <Route path="bannery" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab1.subTab9} description={data.navbar.tab1.subTab9Tooltip} gallery={'NARYJKA.BANNERY'}/>} />
          {/* tab2 */}
          <Route path="vizitki" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab2.subTab1} description={data.navbar.tab2.subTab1Tooltip} gallery={'POLYGRAPHY.VIZITKI'}/>} />
          <Route path="listovie-byklety" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab2.subTab2} description={data.navbar.tab2.subTab2Tooltip} gallery={'POLYGRAPHY.LISTOVIE_BYKLETY'}/>} />
          <Route path="calendars" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab2.subTab3} description={data.navbar.tab2.subTab3Tooltip} gallery={'POLYGRAPHY.CALENDARS'}/>} />
          <Route path="bloknoty" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab2.subTab4} description={data.navbar.tab2.subTab4Tooltip} gallery={'POLYGRAPHY.BLOKNOTY'}/>} />
          {/* tab3 */}
          <Route path="pechat-na-tkani" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab3.subTab1} description={data.navbar.tab3.subTab1Tooltip} gallery={'SHIROKOFORMAT_PECHAT.PECHAT_NA_TKANI'}/>} />
          <Route path="pechat-na-samokleike" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab3.subTab2} description={data.navbar.tab3.subTab2Tooltip} gallery={'SHIROKOFORMAT_PECHAT.PECHAT_NA_SAMOKLEIKE'}/>} />
          <Route path="ploternaya-rezka" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={data.navbar.tab3.subTab3} description={data.navbar.tab3.subTab3Tooltip} gallery={'SHIROKOFORMAT_PECHAT.PLOTTERNAYA_REZKA'}/>} />
          {/* tab4 */}
          <Route path="stands-equipment" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={capitalize(data.navbar.tab4.name)} description={data.navbar.tab4.tooltip} gallery={'V_STANDS_AND_EQPT'}/>} />
          {/* tab5 */}
          <Route path="design-uslugi" element={<RouteInfo contactData={data.hero.contact} contactDialog={data.contactDialog} order={data.general.order} routeName={capitalize(data.navbar.tab5.name)} description={data.navbar.tab5.tooltip} gallery={'DESIGN_USLUGI'}/>} />
        </Routes>
        </div>
      </div>
    </div>
  );
}

export default About;
