import React from 'react';
import emailjs from 'emailjs-com';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import GoogleMaps from './GoogleMaps';
import { getAnalytics, logEvent } from "firebase/analytics";

const Footer = ({footerData, contactData, contactDialog}) => {
  const analytics = getAnalytics();
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [subject, setSubject] = React.useState({subject: ''});
  const [body, setBody] = React.useState({body: ''});
  const [returnEmail, setReturnEmail] = React.useState({returnEmail: ''});
  const [returnPhone, setReturnPhone] = React.useState({returnPhone: ''});

  const handlereturnEmailInput = (e) => {
    setReturnEmail({
      [e.target.name]: e.target.value
    })
  }

  const handleSubjectInput = (e) => {
    setSubject({
      [e.target.name]: e.target.value
    })
  }

  const handleBodyInput = (e) => {
    setBody({
      [e.target.name]: e.target.value
    })
  }

  const handleReturnPhone = (e) => {
    setReturnPhone({
      [e.target.name]: e.target.value
    })
  }

  const handleEmailSend = (e) => {
    e.preventDefault();
    const templateParams = {
      title: subject.subject,
      description: body.body,
      returnEmail: returnEmail.returnEmail,
      returnPhone: returnPhone.returnPhone
    };
    setBackdropOpen(true);
    emailjs.send('service_jtcuk89','template_169rtla', templateParams, 'YdfKgd8CC4VMvsaJr')
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        setSubject({subject: ''});
        setBody({body: ''});
        setReturnEmail({returnEmail: ''});
        setReturnPhone({returnPhone: ''});
        setBackdropOpen(false);
        handleAlertOpen(true);
        // log even to google analytics
        logEvent(analytics, 'successful_form_submit', {
          return_email: returnEmail.returnEmail,
        });
      }, function(err) {
        // alert user to re-attempt
        setBackdropOpen(false);
        alert('FAILED...', err);
      });
  }

  function load(src) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', resolve);
      image.addEventListener('error', reject);
      image.src = src;
    });
  }

  React.useEffect(() => {
    const image = './media/bg1.jpeg';
    load(image).then(() => {
      const BGdiv = document.getElementById('footer');
      BGdiv.style.backgroundImage = `url(${image})`;
      BGdiv.style.animation = 'fade-in 1s';
    });
  });

  return (
    <footer className="text-center text-lg-start text-black" id="footer">
      <section
        className="d-flex justify-content-center justify-content-lg-around p-4 border-bottom"
      >
      </section>
      <section >
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
          <div className="col">
              <h6 className="text-uppercase fw-bold mb-4 text-center">
                {footerData.whyUs}
              </h6>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason1}</p>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason2}</p>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason3}</p>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason4}</p>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason5}</p>
              <p className="text-center"><i className="fas fa-check" style={{color: 'green'}}></i> {footerData.reason6}</p>
            </div>

            <div className="col">
              <div className="email" >
              <h6 className="text-uppercase fw-bold mb-4 text-center">
                {contactDialog.writeEmail}
              </h6>
              <form onSubmit={(e) => handleEmailSend(e)} className="email-form">
                <label style={{color: 'black', fontWeight: 'bold'}} >{contactDialog.weWillGetBackToYou}</label>
                <br />
                <input className="input" type="text" maxLength="50" placeholder={`${contactDialog.emailTitle}...`} name="subject"  value={subject.subject} onChange={(e) => handleSubjectInput(e)} required/>
                <input className="input" type="email" maxLength="50" placeholder={`${contactDialog.returnEmail}...`} name="returnEmail"  value={returnEmail.returnEmail} onChange={(e) => handlereturnEmailInput(e)} required/>
                <input className="input" type="tel" placeholder={`${contactDialog.returnPhone}...`} name="returnPhone"  value={returnEmail.returnPhone} onChange={(e) => handleReturnPhone(e)} required/>
                <textarea className="input" rows="3" maxLength="500" placeholder={`${contactDialog.emailBody}...`} name="body"  value={body.body} onChange={(e) => handleBodyInput(e)} required/>
                <button type="submit" className="button btn">{contactDialog.sendEmailButton}</button>
              </form>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={backdropOpen}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={6000}
                  onClose={handleAlertClose}
                  message="Имейл отправлен успешно"
                  action={handleAlertClose}
                />
              </div>
            </div>
            <div className="col">
              <h6 className="text-uppercase fw-bold mb-4 text-center">
                {contactDialog.map}
              </h6>
              <GoogleMaps />
            </div>
          </div>
        </div>
      </section>

      <div className="text-center p-4 lower-header">
        © {new Date().getFullYear()}
        <a className="text-reset fw-bold" href="https://aokarkau.com" target="_blank" rel="noreferrer"> {footerData.alexOkarkau} </a>
      </div>
    </footer>
  );
}

export default Footer;
