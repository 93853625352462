import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import emailjs from 'emailjs-com';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from "firebase/analytics";


export default function ResponsiveDialog({open, setOpen, contactData, contactDialog, title, prefilledBody}) {
  const analytics = getAnalytics();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setSubject({subject: title});
    setBody({body: prefilledBody});
  }, [title, prefilledBody]);

  const email = contactData.email;

  const [subject, setSubject] = React.useState({subject: ''});
  const [body, setBody] = React.useState({body: ''});
  const [returnEmail, setReturnEmail] = React.useState({returnEmail: ''});
  const [returnPhone, setReturnPhone] = React.useState({returnPhone: ''});
  
  const handlereturnEmailInput = (e) => {
    setReturnEmail({
      [e.target.name]: e.target.value
    })
  }

  const handleSubjectInput = (e) => {
    setSubject({
      [e.target.name]: e.target.value
    })
  }
  
  const handleReturnPhone = (e) => {
    setReturnPhone({
      [e.target.name]: e.target.value
    })
  }

  const handleBodyInput = (e) => {
    setBody({
      [e.target.name]: e.target.value
    })
  }

  const handleEmailSend = (e) => {
    e.preventDefault();
    const templateParams = {
      title: subject.subject,
      description: body.body,
      returnEmail: returnEmail.returnEmail,
      returnPhone: returnPhone.returnPhone
    };
    setBackdropOpen(true);
    emailjs.send('service_jtcuk89','template_169rtla', templateParams, 'YdfKgd8CC4VMvsaJr')
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        setSubject({subject: ''});
        setBody({body: ''});
        setReturnEmail({returnEmail: ''});
        setReturnPhone({returnPhone: ''});
        setBackdropOpen(false);
        // close state
        handleClose();
        // log even to google analytics
        logEvent(analytics, 'successful_form_submit', {
          return_email: returnEmail.returnEmail,
        });
      }, function(err) {
        // alert user to re-attempt
        setBackdropOpen(false);
        alert('FAILED...', err);
      });
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {contactDialog.contact}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <p><i className="fas fa-envelope me-3"></i><a className="footer-link text-black" target="_blank" rel="noreferrer" href={`mailto:${email}`}>{email}</a></p>
          <p>
            <a className="text-black footer-link" href={`tel:${contactData.phone1Link}`} onClick={() => logEvent(analytics, 'phone_clicked', {phone_clicked: true})}><i className="fas fa-phone me-3"/></a>
              <a href="viber://contact?number=%2B375298565557" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, 'redirect_to_viber', {redirect_to_viber: true})}><i className="fab fa-viber me-3"/></a>
              <a href="https://telegram.me/kokopelli_by" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, 'redirect_to_telegram', {redirect_to_telegram: true})}><i className="fab fa-telegram me-3"/></a>
              <a className="text-black footer-link" href={`tel:${contactData.phone1Link}`} onClick={() => logEvent(analytics, 'phone_clicked', {phone_clicked: true})}> {contactData.phone1} </a>
            </p>
            <div className="email" style={{paddingTop: '3%'}}>
              <form onSubmit={(e) => handleEmailSend(e)} className="email-form">
                <label style={{color: 'black', fontWeight: 'bold'}} >{contactDialog.writeEmail}</label>
                <label style={{color: 'black'}} >{contactDialog.weWillGetBackToYou}</label>
                <br />
                <input className="input" type="text" maxLength="50" placeholder={`${contactDialog.emailTitle}...`} name="subject"  value={subject.subject} onChange={(e) => handleSubjectInput(e)} required/>
                <input className="input" type="email" maxLength="50" placeholder={`${contactDialog.returnEmail}...`} name="returnEmail"  value={returnEmail.returnEmail} onChange={(e) => handlereturnEmailInput(e)} required/>
                <input className="input" type="tel" placeholder={`${contactDialog.returnPhone}...`} name="returnPhone"  value={returnEmail.returnPhone} onChange={(e) => handleReturnPhone(e)} required/>
                <textarea className="input" rows="3" maxLength="500" placeholder={`${contactDialog.emailBody}...`} name="body"  value={body.body} onChange={(e) => handleBodyInput(e)} required/>
                <button type="submit" className="button btn">{contactDialog.sendEmailButton}</button>
              </form>
            </div>
            {/* <GoogleMaps /> */}
          </DialogContentText>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" style={{backgroundColor: 'black', color: 'white'}} autoFocus onClick={handleClose}>
            {contactDialog.closeModalButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
