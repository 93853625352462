import React from 'react';
import { Link } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Flag from 'react-world-flags';
import { getAnalytics, logEvent } from "firebase/analytics";

const HeroBanner = ({logo, data, lang, setLang}) => {
  const analytics = getAnalytics();

  const handleLangChange = (e) => {
    setLang(e.target.value);
    localStorage.setItem('lang', JSON.stringify(e.target.value));
  }

  return (
    <div className="hero-banner text-black border-bottom">
      <div className="banner-subcategory">
        <Link to="/">
          <img src="./logos/(logo).png" height="100" alt="Logo" loading="lazy"/>
        </Link>
      </div>
      <div className="banner-subcategory">
        <p>{data.hero.contact.email_text}</p>
        <p><i className="fas fa-envelope me-3"></i><a className="footer-link text-black" target="_blank" rel="noreferrer" href={`mailto:${data.hero.contact.email}`}> {data.hero.contact.email}</a></p>
      </div>
      <div className="banner-subcategory">
        <p>{data.hero.contact.cell_text}</p>
        <p>
        <a className="text-black footer-link" href={`tel:${data.hero.contact.phone1Link}`} onClick={() => logEvent(analytics, 'phone_clicked', {phone_clicked: true})}><i className="fas fa-phone me-3"/></a>
          <a href="viber://contact?number=%2B375298565557" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, 'redirect_to_viber', {redirect_to_viber: true})}><i className="fab fa-viber me-3"/></a>
          <a href="https://telegram.me/kokopelli_by" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, 'redirect_to_telegram', {redirect_to_telegram: true})}><i className="fab fa-telegram me-3"/></a>
          <a className="text-black footer-link" href={`tel:${data.hero.contact.phone1Link}`} onClick={() => logEvent(analytics, 'phone_clicked', {phone_clicked: true})}> {data.hero.contact.phone1} </a>
        </p>
      </div>
      <div className="banner-subcategory" style={{maxWidth: '200px'}}>
        <p>{data.hero.social}</p>
        <a href={data.hero.contact.instaLink} onClick={() => logEvent(analytics, 'redirect_to_instagram', {redirect_to_instagram: true})} target="_blank" rel="noreferrer" className="text-reset social-icon footer-link">
          <InstagramIcon />
        </a>
      </div>

      <div className="banner-subcategory" style={{maxWidth: '200px'}}>
        <div className="d-flex align-items-center text-black">
            <FormControl sx={{ m: 2, minWidth: 70 }}>
            <InputLabel id="demo-simple-select-autowidth-label">{data.language}</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={lang}
              onChange={handleLangChange}
              autoWidth
              label="Age"
            >
              <MenuItem value="en"><Flag code="gb" height="16" /></MenuItem>
              <MenuItem value="ru"><Flag code="ru" height="16" /></MenuItem>
            </Select>
          </FormControl>
          </div>
        </div>
    </div>
  );
}

export default HeroBanner;
