import React from 'react';
import Paper from '@mui/material/Paper';
import DATA from '../data/main-data-set';
import Grid from '@mui/material/Grid';
import { Carousel } from 'react-carousel-minimal';
import Viewer from 'react-viewer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ContactDialog from './ContactDialog';

const RouteInfo = ({routeName, description, gallery, contactData, contactDialog, order}) => {
  const [openContactsDialog, setOpenContactsDialog] = React.useState(false);
  const [ visible, setVisible ] = React.useState(false);
  
  const images = DATA.images;

  const handleContactClick = () => {
    setOpenContactsDialog(true);
  }

  const onClickImage = () => {
    setVisible(true)
  }

  React.useEffect(() => {
    const allImages = document.getElementsByClassName('carousel-image');
    for(let i=0; i<allImages.length; i++) {
      const img = allImages[i];
      img.addEventListener('click', onClickImage);
      img.style.cursor = 'zoom-in';
    }
  }, []);

  const HandleImageCollection = () => {
    const arrayOfKeys = gallery.split('.');
    let currentLevel = images;
    arrayOfKeys.forEach(key => {
      currentLevel = currentLevel[key];
    })
    const arrayWithImages = [];
    Object.values(currentLevel).forEach(image => {
      arrayWithImages.push({
        image,
      })
    })
    // set and pass collection
    return arrayWithImages
  }

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  const imgArray = HandleImageCollection(gallery);
  const imgArrayForModal = HandleImageCollection(gallery).map(img => {
    return {src: img.image, alt: 'kokopelli'}
  })

  return (
    <div className="route-content" id="content">
      <Paper elevation={24} style={{backgroundColor: 'white', color: 'black', border: '1.5px solid black', boxShadow: '20px 20px 50px grey'}}>
        <h1>{routeName}</h1>
        <Grid container spacing={12} columns={2} className="material-ui-grid">
          <Grid item xs={2} sm={2} md={description? 1 : 2}>
            <Carousel
              data={imgArray}
              time={4000}
              width="100%"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={false}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="contain"
              thumbnails={true}
              thumbnailWidth="100px"
            />
          </Grid>
          {
            description?
            <Grid item xs={2} sm={2} md={1} className="card-description">
              <Typography style={{padding: '5%'}}>
                {description}
              <div style={{paddingTop: '5%'}}>
              <Button variant="outlined" style={{backgroundColor: 'white', color: 'black'}} onClick={handleContactClick}>{order}</Button>
              </div>
              </Typography>
            </Grid>
            :
            null
          }
        </Grid>
      </Paper>
      <Viewer
        visible={visible}
        onClose={() => { setVisible(false); } }
        images={imgArrayForModal}
      />
      <ContactDialog open={openContactsDialog} setOpen={setOpenContactsDialog} contactData={contactData} contactDialog={contactDialog} title={routeName} />
    </div>
  );
}

export default RouteInfo;
