import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const AboutCompany = ({routeName, description}) => {
  return (
    <div className="">
      <Paper elevation={24} style={{backgroundColor: 'white', color: 'black', border: '1.5px solid black', padding: '5%'}}>
        <h1>{routeName}</h1>
        <Grid container spacing={12} columns={1} className="material-ui-grid">
          <Grid item xs={1} sm={1} md={1} className="card-description">
            <Typography style={{padding: '5%'}}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default AboutCompany;
