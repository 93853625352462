import React from 'react';
import About from './components/About';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import DATA from './data/main-data-set';
import HeroBanner from './components/HeroBanner'
import GalleryModal from './components/GalleryModal';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';


const firebaseConfig = {
  apiKey: "AIzaSyADNSW7OX7KN5-OQJF0PzZdNqL19hqIlH0",
  authDomain: "kokopelli-minsk.firebaseapp.com",
  projectId: "kokopelli-minsk",
  storageBucket: "kokopelli-minsk.appspot.com",
  messagingSenderId: "724256469329",
  appId: "1:724256469329:web:75cc01ae8f204224c1e370",
  measurementId: "G-33NTYXKKVZ"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

function App() {
  const [lang, setLang] = React.useState(JSON.parse(localStorage.getItem('lang')) || 'ru');

  const [currentCollection, setCurrentCollection] = React.useState(null);
  const [galleryName, setGalleryName] = React.useState('');
  const [open, setOpen] = React.useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <HeroBanner logo={DATA.images.logos.defaultOneNoBG} lang={lang} data={DATA.text[lang]} setLang={setLang}/>
        <Navbar 
          data={DATA.text[lang].navbar}
          images={DATA.images} 
          lang={lang}
          setLang={setLang}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
          galleryName={galleryName}
          setGalleryName={setGalleryName}
          open={open}
          setOpen={setOpen}
        />
        <About
          data={DATA.text[lang]}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
          galleryName={galleryName}
          setGalleryName={setGalleryName}
          open={open}
          setOpen={setOpen}
          contactData={DATA.text[lang].hero.contact} contactDialog={DATA.text[lang].contactDialog}
        />
        <Footer footerData={DATA.text[lang].footer} contactData={DATA.text[lang].hero.contact} contactDialog={DATA.text[lang].contactDialog}/>
        <GalleryModal open={open} setOpen={setOpen} currentCollection={currentCollection} galleryName={galleryName} generalText={DATA.text[lang].general} />
      </BrowserRouter>
    </div>
  );
}

export default App;
