import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import DATA from '../data/main-data-set';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function MultiActionAreaCard({item, setCurrentCollection, setGalleryName, setOpen}) {
  const [lang, setLang] = React.useState(JSON.parse(localStorage.getItem('lang')) || 'ru');
  const navigate = useNavigate();

  React.useEffect(() => {
    setLang(JSON.parse(localStorage.getItem('lang')) || 'ru');
  })

  const handleButtonRedirect = () => {
    navigate(item.link);
  }

  const handleCardClick = (e, collection, title) => {
    const arrayOfKeys = collection.split('.');

    let currentLevel = DATA.images;
    arrayOfKeys.forEach(key => {
      currentLevel = currentLevel[key];
    })
    const arrayWithImages = [];
    Object.values(currentLevel).forEach(image => {
      arrayWithImages.push({
        original: image,
        thumbnail: image,
        loading: 'eager'
      })
    })
    setCurrentCollection(arrayWithImages);
    setGalleryName(title[lang])
    setOpen(true);
  }

  return (
    <Card sx={{ maxWidth: 700 }} onClick={(e) => handleCardClick(e, item.collection, item.title)} style={{border: '1.5px solid black', boxShadow: '20px 20px 50px grey'}}>
      <CardActionArea onClick={handleButtonRedirect}>
        <CardMedia
          style={{padding: '5%'}}
          component="img"
          height="500"
          image={item.image}
          alt="kokopelli-porftolio"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.title[lang]}
          </Typography>
          <Button variant="outlined" style={{backgroundColor: 'black', color: 'white'}} autoFocus  onClick={handleButtonRedirect}>
            {DATA.text[lang].general.findOutMore}
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
